import React, { FC } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

interface DataItem {
    time: string;
    hashrate: number;
}

interface SynchronizedAreaChart2Props {
    data: DataItem[];
}

const SynchronizedAreaChart2: FC<SynchronizedAreaChart2Props> = ({ data }) => {

    const maxValue = Math.max(...data.map((item) => item.hashrate));
    const interval = Math.ceil(maxValue / 5);
    const ticks = Array.from(
        { length: Math.ceil(maxValue / interval) + 1 },
        (_, i) => i * interval
    ); 


    if (!data || data.length === 0) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <div role="status" className="animate-pulse w-full my-2">
                    <div className="h-80 mb-1 bg-gray-200"></div>
                </div>
            </div>
        );
    }

    return (
        <div style={{ width: '100%' }}>
            <div className='mx-auto text-xs md:p-0 h-[300px] md:h-[400px]'>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={data}
                        syncId="anyId"
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                <stop offset="90%" stopColor="#8884d8" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="time"
                            tick={{ fontSize: 12 }}
                            axisLine={{ stroke: '#ccc' }}
                        />
                        <YAxis
                            yAxisId="left"
                            domain={[0, maxValue + interval]}
                            ticks={ticks}
                            tickFormatter={(value) => `${value}`}
                            axisLine={{ stroke: '#ccc' }}
                        />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="hashrate"
                            stroke="#8884d8"
                            fillOpacity={1}
                            fill="url(#colorUv)"
                            yAxisId="left"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default SynchronizedAreaChart2;
