
const SvgDecorator = () => {

    return (
        <div className="absolute top-0 left-0 h-screen hidden lg:block w-full">
            <img
                alt="SVG Decorator"
                src="/assets/ic_bgring1.svg"
                className="absolute opacity-20 md:opacity-50 lg:opacity-100"
                style={{
                    top: `900px`,
                    left: '-230px',
                    right: '0',
                    width: `600px`,
                    height: `600px`,
                }}
                data-aos="fade-right"
            />
            <div className="overflow-hidden ">
                <img
                    alt="SVG Decorator"
                    src="/assets/ic_bgring2.png"
                    className="absolute opacity-20 md:opacity-50 lg:opacity-100"
                    style={{
                        top: `2300px`,
                        left: 'auto',
                        right: '0',
                        height: `600px`,
                    }}
                    data-aos="fade-right"
                />
            </div>
            <div className="overflow-hidden">
                <img
                    alt="SVG Decorator"
                    src="/assets/ic_bgring1.svg"
                    className="absolute opacity-20 md:opacity-50 lg:opacity-100"
                    style={{
                        top: `3060px`,
                        left: '-200px',
                        right: '0',
                        width: `600px`,
                        height: `600px`,
                    }}
                    data-aos="fade-right"
                />
            </div>
            <div className="overflow-hidden">
                <img
                    alt="SVG Decorator"
                    src="/assets/ic_ring2.png"
                    className="absolute opacity-20 md:opacity-50 lg:opacity-100"
                    style={{
                        top: `3410px`,
                        left: 'auto',
                        right: '0',
                        width: `600px`,
                    }}
                    data-aos="fade-right"
                />
            </div>
            <div className="overflow-hidden">
                <img
                    alt="SVG Decorator"
                    src="/assets/ic_bgring4.svg"
                    className="absolute opacity-20 md:opacity-50 lg:opacity-100"
                    style={{
                        top: `4900px`,
                        left: '-100px',
                        right: '0',
                        width: `400px`,
                    }}
                    data-aos="fade-right"
                />
            </div>
        </div>
    );
};

export default SvgDecorator;
