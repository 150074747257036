import React, { useState } from 'react';

const exchangeRate = 1.25; 

const minerData = [
    {
        model: "Bitmain Antminer AL1 Pro",
        hashrate: "16.6 TH/s",
        power: "3730 W",
        coins: "Alephium (ALPH)",
        profit: `$${(18.53 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer AL1",
        hashrate: "15.6 TH/s",
        power: "3510 W",
        coins: "Alephium (ALPH)",
        profit: `$${(17.41 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21e XP Hyd 3U",
        hashrate: "860 TH/s",
        power: "11180 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(17.01 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "IceRiver AL3",
        hashrate: "15 TH/s",
        power: "3500 W",
        coins: "Alephium (ALPH)",
        profit: `$${(16.50 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21 XP Hyd (473Th)",
        hashrate: "473 TH/s",
        power: "5676 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(10.26 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21e XP Hyd (430Th)",
        hashrate: "430 TH/s",
        power: "5590 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(8.51 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Goldshell AL Max",
        hashrate: "8.3 TH/s",
        power: "3350 W",
        coins: "Alephium (ALPH)",
        profit: `$${(6.41 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitdeer SealMiner A2 Hyd",
        hashrate: "446 TH/s",
        power: "7360 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(5.82 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21 XP Immersion (300Th)",
        hashrate: "300 TH/s",
        power: "4050 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(5.65 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "MicroBT WhatsMiner M66S++",
        hashrate: "356 TH/s",
        power: "5518 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(5.33 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "MicroBT WhatsMiner M63S+",
        hashrate: "424 TH/s",
        power: "7208 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(5.13 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21+ Hyd (319Th)",
        hashrate: "319 TH/s",
        power: "4785 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(5.08 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21 XP (270Th)",
        hashrate: "270 TH/s",
        power: "3645 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(5.08 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer S21 Hyd (335Th)",
        hashrate: "335 TH/s",
        power: "5360 W",
        coins: "Bitcoin (BTC)",
        profit: `$${(4.70 * exchangeRate).toFixed(2)}`,
    },
    {
        model: "Bitmain Antminer KS5 Pro (21Th)",
        hashrate: "21 TH/s",
        power: "3150 W",
        coins: "Kaspa (KAS)",
        profit: `$${(3.24 * exchangeRate).toFixed(2)}`,
    },
];





const MinerTable: React.FC = () => {


    return (
        <div className="container mx-auto py-16 sm:py-32 px-4">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-8 space-y-4 sm:space-y-0">
                <h1 className="text-xl sm:text-3xl font-bold">MINER RANKING</h1>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full md:table hidden items-center justify-between table-auto border-collapse bg-white bg-opacity-10 shadow-lg rounded-lg overflow-hidden">
                    <thead className="text-gray-200 uppercase text-sm leading-normal p-2">
                        <tr className='bg-white bg-opacity-10'>
                            <th className="px-6 py-3 text-start">MODEL</th>
                            <th className="px-6 py-3 text-start">HASHRATE</th>
                            <th className="px-6 py-3 text-start">POWER</th>
                            <th className="px-6 py-3 text-start">COIN</th>
                            <th className="px-6 py-3 text-start">PROFIT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {minerData.map((miner, index) => (
                            <tr key={index} className="text-gray-200 text-sm border-b border-gray-500">
                                <td className="px-6 py-4 flex items-center font-semibold">
                                    <i className="fas fa-microchip text-blue-500 mr-2"></i>
                                    {miner.model}
                                </td>
                                <td className="px-6 py-4">{miner.hashrate}</td>
                                <td className="px-6 py-4">{miner.power}</td>

                                <td className="px-6 py-4 flex justify-start items-center">
                                    <img src={miner.coins === 'Bitcoin (BTC)'
                                        ? '/assets/currency-btc.png'
                                        : miner.coins === 'Alephium (ALPH)'
                                            ? '/assets/currency-alph.png'
                                            : '/assets/currency-kas.png'
                                    } alt="Coin Icon" className="w-5 h-5 mr-2" />
                                    <p>{miner.coins}</p>
                                </td>
                                <td className="px-6 py-4">{miner.profit} /day</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {minerData.map((miner, index) => (
                    <table className="md:hidden min-w-full bg-opacity-10 shadow-lg rounded-lg overflow-hidden my-1">
                        <tbody className='text-center'>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">MODEL</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5 ">{miner.model}</td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">HASHRATE</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5">{miner.hashrate}</td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">POWER</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5">{miner.power}</td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">COIN</th>
                                <td className="px-6 py-3  bg-white bg-opacity-10 flex justify-start items-center">
                                    <img src={miner.coins === 'Bitcoin (BTC)'
                                        ? '/assets/currency-btc.png'
                                        : miner.coins === 'Alephium (ALPH)'
                                            ? '/assets/currency-alph.png'
                                            : '/assets/currency-kas.png'
                                    } alt="Coin Icon" className="w-5 h-5 mr-2" />
                                    <p>{miner.coins}</p>
                                </td>
                            </tr>
                            <tr key={index} className="text-sm border-b border-gray-500">
                                <th className="px-6 py-3 bg-white bg-opacity-10 w-1/2">PROFIT</th>
                                <td className="px-6 py-4 w-1/2 bg-white bg-opacity-5">{miner.profit} /day</td>
                            </tr>
                        </tbody>
                    </table>
                ))}
            </div>
        </div>
    );
};

export default MinerTable;
