export const WhyChooseUsComponent = () => {

    return (
        <div className="text-center mb-12 flex flex-wrap justify-center bg-white py-16 bg-opacity-10">
            <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-0">
                WHY CHOOSE US
            </h1>
            <div className="container flex flex-wrap justify-center">
                <div className="text-center w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4">
                    <div className="inline-block mb-4">
                        <img
                            alt="coin"
                            className="w-2/3 mx-auto"
                            src="/assets/ic_coin3.svg"
                        />
                    </div>
                    <h2 className="text-lg font-bold uppercase">
                        Trusted Expertise & Local Support
                    </h2>
                    <p className="mt-2">
                        With years of experience and a deep understanding of the UAE’s unique mining environment, we offer personalized support tailored to your needs. Our local team ensures seamless operations and efficient solutions at every step.
                    </p>
                </div>
                <div className="text-center w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4">
                    <div className="inline-block mb-4">
                        <img
                            alt="coin"
                            className="w-2/3 mx-auto"
                            src="/assets/ic_coin1.svg"
                        />
                    </div>
                    <h2 className="text-lg font-bold uppercase">
                        Fast & Secure Payouts
                    </h2>
                    <p className="mt-2">
                        Benefit from prompt and secure reward distributions, enabling you to focus on mining while we take care of the backend to ensure timely and accurate payouts.
                    </p>
                </div>
                <div className="text-center w-full sm:w-1/2 md:w-1/3 xl:w-1/3 p-4">
                    <div className="inline-block mb-4">
                        <img
                            alt="coin"
                            className="w-2/3 mx-auto"
                            src="/assets/ic_coin2.svg"
                        />
                    </div>
                    <h2 className="text-lg font-bold uppercase">
                        Cutting-Edge Infrastructure & Reliability
                    </h2>
                    <p className="mt-2">
                        Leverage state-of-the-art technology and infrastructure designed to deliver maximum uptime, efficiency, and secure mining operations, ensuring you stay ahead of the competition.
                    </p>
                </div>
            </div>
        </div>
    );
};