import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import MinerTable from "./MInerData";

interface PriceEstimationComponentProps { }

const PriceEstimationComponent: React.FC<PriceEstimationComponentProps> = () => {
    const [monthlyEarnings, setMonthlyEarnings] = useState<string>('65000.000');
    const [estimatedPurchasePrice, setEstimatedPurchasePrice] = useState<string>((parseFloat(monthlyEarnings) * 12 / 1.4).toFixed(3));

    const inputEarningsRef = useRef<HTMLInputElement | null>(null);
    const inputPriceRef = useRef<HTMLInputElement | null>(null);

    const handleIconClick = () => {
        inputEarningsRef.current?.focus();
    };

    const handleIconClickPrice = () => {
        inputPriceRef.current?.focus();
    };

    const handleMonthlyEarningsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value; // Get the raw input value
        setMonthlyEarnings(value); // Update state with the raw value

        // Convert to number and calculate estimated purchase price
        const numericValue = parseFloat(value) || 0;
        setEstimatedPurchasePrice(((numericValue * 12) / 1.4).toFixed(3)); // Update estimated purchase price
    };

    const handleEstimatedPurchasePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value; // Get the raw input value
        setEstimatedPurchasePrice(value); // Update state with the raw value

        // Convert to number and calculate monthly earnings
        const numericValue = parseFloat(value) || 0;
        setMonthlyEarnings(((numericValue * 1.4) / 12).toFixed(3)); // Update monthly earnings
    };

    return (
        <>
            <h2 className="text-xl sm:text-4xl font-bold text-center py-8">
                HOW MUCH YOU WANT TO EARN MONTHLY
            </h2>
            <div className="text-center w-full bg-white py-8 md:py-16 bg-opacity-10">
                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-stretch lg:space-x-8 space-y-6 lg:space-y-0 w-full">
                    <div className="bg-white px-6 py-4 rounded shadow w-11/12 lg:w-1/5">
                        <p className="text-gray-500">MONTHLY EARNINGS:</p>
                        <div className="flex justify-center items-center gap-3">
                            <input
                                ref={inputEarningsRef}
                                type="text" // Change to text to allow for easier input handling
                                value={monthlyEarnings}
                                onChange={handleMonthlyEarningsChange}
                                className="text-2xl font-bold text-gray-600 w-full focus:outline-none text-center"
                            />
                            <FontAwesomeIcon
                                icon={faPen}
                                className="text-gray-500 cursor-pointer"
                                onClick={handleIconClick}
                            />
                        </div>
                    </div>
                    <div className="text-gray-100 bg-transparent px-6 py-4 rounded w-full lg:w-1/5">
                        <p>ESTIMATED PURCHASE PRICE</p>
                        <div className="flex justify-center items-center gap-3">
                            <input
                                ref={inputPriceRef}
                                type="text"
                                value={estimatedPurchasePrice}
                                onChange={handleEstimatedPurchasePriceChange}
                                className="text-2xl font-bold text-gray-100 w-full bg-transparent focus:outline-none text-center"
                            />
                            <FontAwesomeIcon
                                icon={faPen}
                                className="text-gray-50 cursor-pointer"
                                onClick={handleIconClickPrice}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <MinerTable />
        </>
    );
};

export default PriceEstimationComponent;