import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface NavbarProps {
    navBgColor: string;
    navIconSize: string;
    joinSegmentRef: React.RefObject<HTMLDivElement>;
    priceEstimationRef: React.RefObject<HTMLDivElement>;
    aboutRef: React.RefObject<HTMLDivElement>;
    whyChooseUsRef: React.RefObject<HTMLDivElement>;
}

const Navbar: React.FC<NavbarProps> = ({
    navBgColor,
    navIconSize,
    joinSegmentRef,
    priceEstimationRef,
    aboutRef,
    whyChooseUsRef,
}) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();

    const toggleNav = () => setIsNavOpen(!isNavOpen);

    return (
        <nav className={`fixed top-0 flex w-full items-center justify-between py-2 z-10 md:flex-wrap md:justify-start md:py-4 ${navBgColor}`}>
            <div className="lg:container mx-auto flex w-full flex-col md:flex-wrap items-start justify-between ">
                <div className="flex justify-between w-full p-2 md:p-0">
                    <div onClick={() => navigate('/')} className="cursor-pointer md:hidden block items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 md:mb-0 md:mt-0">
                        <img src="/assets/seg_logo.png" className='h-7' alt="Flowbite Logo" />
                    </div>
                    <button
                        className="block border-0 bg-transparent px-2 text-black/50 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 md:hidden"
                        type="button"
                        onClick={toggleNav}
                        aria-controls="navbarSupportedContent1"
                        aria-expanded={isNavOpen}
                        aria-label="Toggle navigation"
                    >
                        <span className="[&>svg]:w-7 [&>svg]:stroke-black/50 dark:[&>svg]:stroke-neutral-200">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="flex justify-start items-center w-full md:p-0">
                    <div className={`md:flex-grow items-center md:!flex md:basis-auto ${isNavOpen ? 'block' : 'hidden'} h-auto sm:h-auto md:h-auto p-8 md:p-0 bg-[#3A3D41] md:bg-transparent w-full md:w-auto fixed md:relative top-0 `} id="navbarSupportedContent1" >
                        <div onClick={() => navigate('/')} className="hidden cursor-pointer md:block mb-4 me-5 ms-2 mt-3 items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 md:mb-0 md:mt-0">
                            <img src="/assets/seg_logo.png" className={navIconSize} alt="Flowbite Logo" />
                        </div>
                        <div className="w-full flex justify-end md:hidden">
                            <FontAwesomeIcon icon={faXmark} onClick={toggleNav} />
                        </div>

                        <div className="relative items-center gap-2 md:hidden block my-0 md:my-4">
                            <button onClick={() => navigate('/login')} className=" bg-transparent mr-2 md:mr-0 mb-4 md:mb-0 hover:bg-yellow-500 text-white hover:text-white py-2 px-4 border border-white hover:border-transparent rounded-3xl text-xs sm:text-sm">Sign In</button>
                            <button onClick={() => navigate('/signup')} className="bg-yellow-500 hover:bg-yellow-600 text-white hover:text-white py-2 px-4 border-none hover:border-transparent rounded-3xl text-xs sm:text-sm">Sign Up</button>
                        </div>
                        <ul className="list-style-none me-auto flex flex-col ps-0 md:flex-row sm:h-auto md:h-auto justify-center mt-4 md:mt-0 relative h-full">
                            {[
                                { label: "Shop", ref: joinSegmentRef },
                                { label: "Facility Setup", ref: priceEstimationRef },
                                { label: "About Us", ref: aboutRef },
                                { label: "Why Choose Us", ref: whyChooseUsRef },
                            ].map((item, index) => (
                                <li key={index} className="mb-4 md:mb-0 md:pe-2 mx-1 relative">
                                    <div
                                        className="text-white transition duration-200 hover:font-bold md:px-2 cursor-pointer"
                                        onClick={() => {
                                            if (item.ref.current) {
                                                const navHeight = document.querySelector('nav')?.offsetHeight;
                                                window.scrollTo({ top: item.ref.current.offsetTop - (navHeight || 0), behavior: 'smooth' });
                                            }
                                            toggleNav();
                                        }}
                                    >
                                        {item.label}
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="flex flex-col items-center justify-center md:hidden">
                            <hr className="w-full border-t border-gray-400 mb-1" />
                            <div onClick={() => navigate('/')} className="mb-2 mt-2 text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 md:mb-0 md:mt-0">
                                <img src="/assets/seg_logo.png" className='h-10' alt="Flowbite Logo" />
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <button onClick={() => navigate('/login')} className="bg-transparent hover:bg-yellow-500 text-white hover:text-white py-2 px-4 border border-white hover:border-transparent rounded-3xl text-xs sm:text-sm mr-2">Sign In</button>
                        <button onClick={() => navigate('/signup')} className="bg-yellow-500 hover:bg-yellow-600 text-white hover:text-white py-2 px-4 border-none hover:border-transparent rounded-3xl text-xs sm:text-sm">Sign Up</button>
                    </div>
                </div>

            </div>
        </nav>
    );
};

export default Navbar;
