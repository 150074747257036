import React, { forwardRef, RefObject } from 'react';


export const JoinSegmentCloudComponent = forwardRef<HTMLDivElement>(
    (props, ref) => {

        const handleContactClick = () => {
            const sectionRef = ref as RefObject<HTMLDivElement>;
            if (sectionRef && sectionRef.current) {
                sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };

        return (
            <div className="text-center w-full bg-white p-8 md:py-16 bg-opacity-10 rounded-2xl md:rounded-none">
                <div className="container mx-auto md:flex md:justify-between items-center relative">
                    <div className="md:text-left w-full lg:w-1/2 xl:w-1/2 md:py-16 text-start">
                        <h1 className="text-2xl md:text-4xl font-bold mb-3 md:mb-0">
                            Join SegPool
                        </h1>
                        <p className="my-4 text-md md:text-lg">
                            Your gateway to seamless crypto mining in the UAE. Secure your rewards and elevate your mining experience with our cutting-edge infrastructure and dedicated support. Maximize your mining potential with fast, efficient, and reliable payouts. Whether you’re a seasoned miner or just starting, SegPool offers the tools and resources to ensure your success in the evolving blockchain landscape. Join now and be part of the UAE’s premier crypto mining community.
                        </p>
                        <button
                            className="z-50 w-auto px-4 md:px-8 py-2 md:py-4 my-4 font-bold bg-[linear-gradient(98.9deg,_#FCBE13_-5.32%,_#EB910D_74.21%)] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-500 cursor-pointer"
                            onClick={handleContactClick}
                        >
                            JOIN NOW
                        </button>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 flex justify-center items-center p-4 md:p-0 " data-aos="fade-right">
                        <img
                            alt="Crypto Mining Illustration"
                            className="w-2/3 lg:w-1/2 xl:w-1/2"
                            src="/assets/ic_miner.png"
                        />
                    </div>
                </div>
            </div>
        );
    }
);
