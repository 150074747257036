import React, { useState, ChangeEvent, FormEvent } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


interface FormData {
    fullName: string;
    email: string;
    subject: string;
    message: string;
    phone: string;
}

interface FormErrors {
    fullName?: string;
    email?: string;
    subject?: string;
    message?: string;
    phone?: string;
}

export const Contact: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        fullName: '',
        email: '',
        subject: '',
        message: '',
        phone: '',
    });

    const [errors, setErrors] = useState<FormErrors>({});


    const validate = (): FormErrors => {
        const tempErrors: FormErrors = {};
        if (!formData.fullName) tempErrors.fullName = 'Full Name is required';
        if (!formData.email) {
            tempErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = 'Email is invalid';
        }
        if (!formData.message) tempErrors.message = 'Message is required';
        if (!formData.phone) tempErrors.phone = 'Phone is required';
        else if (!/^\d{10,15}$/.test(formData.phone.replace(/\D/g, ''))) {
            tempErrors.phone = 'Phone number is invalid';
        }
        return tempErrors;
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            const { fullName, email, subject, message, phone } = formData;
            const whatsappMessage = `
            👤 *Full Name:* ${fullName}%0A
            📧 *Email:* ${email}%0A
            📝 *Subject:* ${subject}%0A
            💬 *Message:* ${message}%0A
            📱 *Phone:* ${phone}%0A
        `.trim();
            const whatsappNumber = '+971528364053';

            const whatsappURL = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;
            window.open(whatsappURL, '_blank');

            alert('Redirecting to WhatsApp chat...');
            setFormData({ fullName: '', email: '', subject: '', message: '', phone: '' });
            setErrors({});
        } else {
            setErrors(validationErrors);
        }
    };


    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | string
    ) => {
        if (typeof e === 'string') {
            setFormData((prev) => ({ ...prev, phone: e }));
        } else {
            const { name, value } = e.target;
            setFormData((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: undefined }));
        }
    };

    return (
        <div className="container mx-auto py-4 md:py-16 px-8">
            <div className="flex flex-col md:flex-row justify-between items-start">
                <div className="w-full md:w-1/2 pr-4 mb-4">
                    <h2 className="text-2xl md:text-4xl font-bold mb-4">
                        PLEASE CONTACT US FOR ANY QUESTIONS
                    </h2>
                    <p className="mb-4">
                        PHONE NUMBER:
                        <br />
                        +971528364053
                    </p>
                    <p className="mb-4">
                        EMAIL ADDRESS:
                        <br />
                        <a className="text-yellow-500" href="mailto:asil@segments.ae">
                            asil@segments.ae
                        </a>
                    </p>
                    <p className="mb-4">
                        ADDRESS:
                        <br />
                        Based in Abu Dhabi, UAE.
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <form onSubmit={handleSubmit}>
                        <div className="w-full max-w-md mx-auto space-y-4">
                            <input
                                className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.fullName ? 'ring-red-500' : 'focus:ring-blue-500'}`}
                                placeholder="Full Name"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                type="text"
                            />
                            {errors.fullName && <span className="text-red-500 text-sm">{errors.fullName}</span>}

                            <input
                                className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.email ? 'ring-red-500' : 'focus:ring-blue-500'}`}
                                placeholder="E-mail"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                type="email"
                            />
                            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}

                            <PhoneInput
                                country={'ae'}
                                value={formData.phone}
                                onChange={handleChange}
                                inputStyle={{
                                    width: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    color: '#ffffff',
                                    borderRadius: '0.375rem',
                                    border: errors.phone ? '1px solid red' : '1px solid transparent',
                                }}
                                buttonStyle={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                }}
                                dropdownStyle={{
                                    backgroundColor: '#ffffff',
                                    color: '#000'
                                }}
                                placeholder="Phone"
                            />
                            {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}

                            <input
                                className="w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                type="text"
                            />

                            <textarea
                                className={`w-full p-2 bg-white bg-opacity-10 text-white rounded-md focus:outline-none focus:ring-2 ${errors.message ? 'ring-red-500' : 'focus:ring-blue-500'} h-32`}
                                placeholder="Message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                            {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}

                            <div className="w-full flex justify-center md:justify-end">
                                <button
                                    type="submit"
                                    className="w-auto px-8 py-3 font-bold bg-[linear-gradient(98.9deg,_#FCBE13_-5.32%,_#EB910D_74.21%)] text-white rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
                                >
                                    SEND
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
