import { faCoins, faFilePowerpoint, faHandHoldingDollar, faMagnet, faMoneyBill1, faMoneyCheck, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const About = () => {
    return (
        <div className="container mx-auto py-8 md:py-32 px-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="w-full md:w-2/3 pr-4 ">
                    <h1 className="text-2xl md:text-4xl font-bold mb-3 md:mb-8">ABOUT SEGMENTS</h1>
                    <h2 className="text-lg md:text-4xl mb-3 md:mb-8">Crypto Mining Company in Dubai, UAE</h2>
                    <p className="text-sm md:text-lg mb-4">
                        We have mined Bitcoin for ourselves since 2017. We know the problems you will face in UAE. Procurement, infrastructure, hosting, maintenance, risk management, and financing.
                    </p>
                    <p className="text-sm md:text-lg">
                        Our bitcoin mining journey in UAE in the last two years has helped us prepare a much easier path for you, through us! We help you get the most profitable crypto mining machines in Dubai at the best rates and host them for you here in the Middle east. We help you set up the infrastructure, IoT-based controls, and maintenance. We can even talk to you about risk management and hedging. Come visit us at our crypto-hosting center in Abu Dhabi, UAE!
                    </p>
                </div>
                <div className="w-full md:w-1/3 px-2 py-8" >
                    <div className="grid grid-cols-2 gap-4">
                        <div data-aos="fade-right" className="bg-black border border-gray-500 shadow-sm shadow-gray-300 bg-opacity-10 p-6 rounded-lg rounded-tl-3xl text-center w-full flex items-center justify-center">
                            <div className="flex flex-col items-center justify-center">
                                <FontAwesomeIcon icon={faHandHoldingDollar} className="text-gray-50 h-5 md:h-10" />
                                <p className="text-sm md:text-xl mt-2">1% FPPS COMMISSION</p>
                            </div>
                        </div>
                        <div data-aos="fade-right" className="bg-white bg-opacity-10 p-6 rounded-lg rounded-tr-3xl text-center w-full md:aspect-square flex items-center justify-center">
                            <div className="flex flex-col items-center justify-center">
                                <FontAwesomeIcon icon={faMagnet} className="text-gray-50 h-5 md:h-10" />
                                <p className="text-sm md:text-xl mt-2">1EH TOTAL HASHRATE</p>
                            </div>
                        </div>
                        <div data-aos="fade-right" className="bg-white bg-opacity-10 p-6 rounded-lg rounded-bl-3xl text-center w-full md:aspect-square flex items-center justify-center">
                            <div className="flex flex-col items-center justify-center">
                                <FontAwesomeIcon icon={faToggleOn} className="text-gray-50 h-5 md:h-10" />
                                <p className="text-sm md:text-xl mt-2">AUTOCOIN SWITCH</p>
                            </div>
                        </div>
                        <div data-aos="fade-right" className="bg-white bg-opacity-10 p-6 rounded-lg rounded-br-3xl text-center w-full md:aspect-square flex items-center justify-center">
                            <div className="flex flex-col items-center justify-center">
                                <FontAwesomeIcon icon={faCoins} className="text-gray-50 h-5 md:h-10" />
                                <p className="text-sm md:text-xl mt-2">LIGHTNING PAYOUT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};