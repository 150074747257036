
export const Footer = () => {
    return (
        <div className="bg-[#012B48]">
            <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="flex justify-center items-center">
                    <div className="flex items-center">
                        <img src="/assets/seg_logo.png" className="h-16" alt="Flowbite Logo" />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-around mt-12 space-y-8 md:space-y-0">
                    <div>
                        <h2 className="text-lg font-semibold mb-4 flex items-center">
                            CATEGORIES
                            <span className="flex-grow h-0.5 bg-gray-500 ml-2"></span>
                        </h2>
                        <ul>
                            <li className="mb-2">Shop</li>
                            <li className="mb-2">Facility Setup</li>
                            <li className="mb-2">About Us</li>
                            <li className="mb-2">Why Choose Us</li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="text-lg font-semibold mb-4 flex items-center">
                            CONTACTS
                            <span className="flex-grow h-0.5 bg-gray-500 ml-2"></span>
                        </h2>
                        <ul>
                            <li className="mb-2">
                                <i className="fas fa-envelope mr-2"></i>
                                asil@segments.ae
                            </li>
                            <li className="mb-2">
                                <i className="fas fa-phone mr-2"></i>
                                +971528364053
                            </li>
                            <li className="mb-2">
                                <i className="fas fa-map-marker-alt mr-2"></i>
                                Based in Abu Dhabi, UAE.
                            </li>
                        </ul>
                    </div>
                    {/* <div>
                        <h2 className="text-lg font-semibold mb-4 flex items-center">
                            SUBSCRIBE
                            <span className="flex-grow h-0.5 bg-gray-500 ml-2"></span>
                        </h2>
                        <form className="flex">
                            <input className="p-2 rounded-l bg-[#1A3A5F] text-white w-2/3 sm:w-auto" placeholder="E-mail" type="email" />
                            <button className="p-2 bg-[#F5A623] text-white rounded-r w-1/3 sm:w-auto" type="submit">
                                SEND
                            </button>
                        </form>
                    </div> */}
                </div>
                <div className="mt-12 text-center border-t border-gray-700 pt-4">
                    <p>Copyright ©2024 Segments Cloud Computing L.L.C.</p>
                    <p>All Rights Reserved</p>
                </div>
            </div>
        </div>
    );
};